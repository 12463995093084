






import Vue from 'vue'
import Component from 'vue-class-component'
import SessionDetail from '../../components/sessions/SessionDetails.vue'

@Component({
  components: {
    SessionDetail
  }
})
export default class ChargeSessionDetail extends Vue {

}
